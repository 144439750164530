<template>
  <div class="w-screen max-w-sm p-7-5 xl:flex xl:flex-1 xl:flex-col xl:justify-between xl:w-full xl:max-w-full only-xl:p-7-5 only-md:p-7-5 sm:p-4">
    <div>
      <div class="flex justify-center">
        <div class="text-center text-xl33 font-bold mx-auto md:text-xl24">{{$t('navigation.logout')}}</div>
      </div>
    </div>

    <div class="font-semibold text-center mt-5">
      {{$t('authentication.confirm_logout')}}
    </div>

    <!-- Buttons -->
    <div class="flex justify-between mt-8">
      <ButtonV2
        @onClick="cancel"
        :label="$t('cancel')"
        :inactive="isLoading"
        version="secondary"
        wide
      />
      <ButtonV2
        @onClick="logout"
        :label="$t('navigation.logout')"
        testId="btn-logout"
        :inactive="isLoading"
        class="ml-4"
        wide
      />
    </div>
  </div>
</template>

<script>
import USER_LOGOUT from '@/graphql/mutations/UserLogout.gql';
import ButtonV2 from '@/stories/misc/ButtonV2';

export default {
  name: 'ConfirmLogout',
  components: {
    ButtonV2,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    async logout() {
      this.isLoading = true;
      try {
        await this.apolloApiCall({
          mutation: USER_LOGOUT,
        });
        await this.handleLogout();
        this.$router.push('/login').catch(()=>{});
        this.hideModal();
      } catch (err) {
        await this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },

    cancel() {
      this.isMobileDevice ? this.showModal('MobileMenu') : this.hideModal();
    },
  },
};
</script>

<style scoped>
.gradient-container {
  @apply inline-block leading-none;
  background: transparent linear-gradient(192deg, #8479E2 15%, #42DDB3 100%) 0% 0% no-repeat padding-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
